div.lccs-top-container {
  background-color: white;
  min-height: 1080px;
}

div.lccs-container {
  display: flex;
  flex-wrap: wrap;
}

.lccs-title {
  margin-top: 10px;
  padding-right: 16px;
  padding-left: 24px;
  background-color: white;
}

div.lccs-button {
  width: 100%;
  /* text-align: center; */
  margin-left: 20px;
  margin-top: 15px;
}

.button-label {
  cursor: pointer;
  overflow: hidden !important;
  color: fieldtext;
  display: inline-block;
  border: solid 1px;
  border-radius: 2px;
  padding-block: 1px;
  padding-inline: 6px;
}

.button-label.disabled {
  color: #DDD;
  background-color: #FFF;
  cursor: not-allowed;
}

.delete-button {
  cursor: pointer;
  overflow: hidden !important;
  color: fieldtext;
  display: inline-block;
  border: solid 1px;
  border-radius: 2px;
  padding-block: 1px;
  padding-inline: 6px;
  height: 25px;
}

.select-file {
  display: inline-block;
  width: 250px;
}

.explanatory {
  overflow: hidden !important;
  color: fieldtext;
  display: inline-block;
  border-radius: 2px;
  padding-block: 1px;
  padding-inline: 6px;
}

.explanatory.disabled {
  color: #DDD;
}

div.loading-container {
  display: none;
}

.loading-image {
  width: 20px;
  margin-left: 5px;
}

.loading-text {
  font-size: 12px;
  color: gray;
  margin-left: 5px;
}

.required {
  color: red;
  vertical-align: top;
  font-size: 12px;
  margin-left: 3px;
}
